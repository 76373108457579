import * as api from '../shared/api'

import { t, intakeRelativeTimeFromFasting } from '../shared/lib/helper'

import './fastings.css'

import deleteSVG from '../../icons/streamline-micro-line/delete.svg'

import {
    numberWithFraction,
    hoursUnit,
    fastingHours,
} from '../shared/lib/helper'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function Fastings() {
    const setMinimumHours = async (h) => {
        await api.updateUserSettings({
            minimum_fasting_hours_filter: h,
        })
        // setFastings(null);
        loadFastings({ minimum_fasting_hours: h })
        _setMinimumHours(h)
    }

    async function deleteFasting(ev, id) {
        await api.deleteFasting(id)
        ev.target.closest('li').style.display = 'none'
    }

    const [fastings, setFastings] = useState(null)
    const [minimumHours, _setMinimumHours] = useState(null)
    const [page, setPage] = useState(1)
    const [hasNextPage, setHasNextPage] = useState(false)
    const navigate = useNavigate()

    function listOfFastings() {
        if (!fastings || fastings.length === 0) {
            return <div></div>
        }
        let goalReached = (f) => Math.round(fastingHours) >= f.targeted_hours
        let filteredFastings = fastings.filter((f) => f.ends_at)

        return filteredFastings.map((f) => {
            return (
                <li key={f.id}>
                    <div className="left-and-right">
                        <div onClick={(e) => navigate(`/fastings/${f.id}`)}>
                            <header>
                                <b>
                                    {numberWithFraction(fastingHours(f))}{' '}
                                    {hoursUnit(fastingHours(f))} {t('fasted')}
                                </b>
                                <span className="end-date">
                                    {new Date(f.ends_at).toLocaleDateString()}
                                </span>
                            </header>
                            <div className="details">
                                <div className="targeted">
                                    <span>
                                        {goalReached(f) && ' 🎉'}{' '}
                                        {Math.ceil(
                                            (100 / f.targeted_hours) *
                                                (f.duration_in_minutes / 60)
                                        )}
                                        % reached
                                    </span>
                                    <span>
                                        {t('n hours targeted', {
                                            targeted_hours: f.targeted_hours,
                                        })}
                                    </span>
                                </div>
                            </div>
                            {f.intakes.length > 0 && (
                                <div className="intakes">
                                    <ul>
                                        {f.intakes.map((intake) => (
                                            <li key={intake.id}>
                                                <b>
                                                    {t(
                                                        intake.which[0].toUpperCase() +
                                                            intake.which.substr(
                                                                1
                                                            )
                                                    )}
                                                </b>{' '}
                                                {intake.note && (
                                                    <span>({intake.note})</span>
                                                )}{' '}
                                                @{' '}
                                                {intakeRelativeTimeFromFasting(
                                                    f,
                                                    intake
                                                )}{' '}
                                                {t('hours of fasting')}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className="actions">
                            <img
                                src={deleteSVG}
                                alt="delete"
                                onClick={(e) =>
                                    confirm(t('Sure?')) &&
                                    deleteFasting(e, f.id)
                                }
                            ></img>
                        </div>
                    </div>
                </li>
            )
        })
    }

    async function loadFastings({
        minimum_fasting_hours = null,
        append = false,
    } = {}) {
        let currentPage = page
        if (append) {
            currentPage++
        } else {
            currentPage = 1
        }
        let res = await api.fastings({
            per_page: 25,
            page: currentPage,
            minimum_fasting_hours: minimum_fasting_hours || minimumHours,
        })
        if (!res.data || !res.data.data) {
            setFastings([])
            setPage(null)
            return
        }
        let data = res.data.data

        setHasNextPage(Boolean(res.data.links.next))
        setPage(currentPage)

        if (append) {
            // merge data
            setFastings(fastings.concat(data))
        } else {
            setFastings(data)
        }
    }

    useEffect(() => {
        if (minimumHours === null) {
            _setMinimumHours(0)
            api.user()
                .then((res) => {
                    let settings = JSON.parse(res.data.data.settings)
                    _setMinimumHours(settings.minimum_fasting_hours_filter)
                    loadFastings({
                        minimum_fasting_hours:
                            settings.minimum_fasting_hours_filter,
                    })
                })
                .catch((err) => console.error(err))
        }
    })

    return (
        <div className="fastings">
            <header>
                <h1>{t('Past Fastings')}</h1>
            </header>
            <div className="filter">
                {t('Minimum Hours')}: &nbsp;
                <select
                    className="select minimum-hours"
                    value={minimumHours || 0}
                    onChange={(e) => setMinimumHours(e.target.value)}
                >
                    <option value="36">36 {t('hours')}</option>
                    <option value="24">24 {t('hours')}</option>
                    <option value="20">20 {t('hours')}</option>
                    <option value="16">16 {t('hours')}</option>
                    <option value="12">12 {t('hours')}</option>
                    <option value="10">10 {t('hours')}</option>
                    <option value="8">8 {t('hours')}</option>
                    <option value="6">6 {t('hours')}</option>
                    <option value="5">5 {t('hours')}</option>
                    <option value="4">4 {t('hours')}</option>
                    <option value="3">3 {t('hours')}</option>
                    <option value="2">2 {t('hours')}</option>
                    <option value="1">1 {t('hours')}</option>
                    <option value="0">{t('All')}</option>
                </select>
            </div>
            {fastings === null && (
                <div className="loading text-center">{t('Loading')}…</div>
            )}
            <ul className="fastings-list">
                {listOfFastings()}
                {hasNextPage && (
                    <div className="text-center load-more">
                        <button
                            className="button"
                            onClick={(ev) => loadFastings({ append: true })}
                        >
                            {t('Load more')}
                        </button>
                    </div>
                )}
                <div className="small-and-gentle-hint">
                    {!hasNextPage && fastings?.length > 0 ? (
                        <span>{t('No more fastings found')}</span>
                    ) : !fastings || fastings.length === 0 ? (
                        <span>{t('Nothing found. Maybe check filter?')}</span>
                    ) : (
                        ''
                    )}
                    <br></br>
                </div>
            </ul>
        </div>
    )
}

export default Fastings
