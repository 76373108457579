{
  "fr": {
    "Yeah, you reached your fasting goal": "Félicitations, tu as atteint ton objectif de jeûne",
    "Your fasting will be completed": "Le jeûne prend fin",
    "hour": "Heure",
    "hours": "Heures",
    "Hours": "Heures",
    "Days": "Jours",
    "Fasting started at": "Le jeûne a commencé vers",
    "Started At": "A commencé vers",
    "Ended At": "A pris fin vers",
    "Password (at least 8 characters)": "Mot de passe (au moins 8 caractères)",
    "Confirm Password": "Répéter le mot de passe",
    "Already having an account? Login here": "Vous avez déjà un compte d'utilisateur ? Connectez-vous ici",
    "Password must be at least n characters long": "Le mot de passe doit comporter au moins %{count} de caractères.",
    "Please use a valid email": "Veuillez indiquer une adresse e-mail correcte",
    "Please try another email": "Veuillez utiliser une autre adresse e-mail",
    "Signup done! Please confirm your E-Mail and you are ready to use fastingMonster": "L'inscription est réussie. Pour finir, clique sur le lien dans l'e-mail de confirmation et tu peux utiliser fasting.monster.",
    "Add notes after fasting": "Écrire la note après le jeûne",
    "Saved changes": "Modifications enregistrées",
    "Targeted hours of fasting": "Durée prévue du jeûne",
    "InTotalXHoursFasted": "Jeûné au total <b>%{hoursOfFasting}</b>.",
    "Signup here if no account": "Pas encore de compte d'utilisateur ? S'inscrire ici",
    "No more fastings found": "Aucune autre période de jeûne trouvée",
    "Nothing found. Maybe check filter?": "Rien n'a été trouvé. Vérifier le filtre, le cas échéant",
    "Loading": "En cours de chargement",
    "Past Fastings": "Intervalles de jeûne",
    "Minimum Hours": "Nombre minimal d'heures",
    "All": "Tous les",
    "n hours targeted": "%{targeted_hours} heures prévues",
    "n hours fasted": "%{hours} heures de fasted",
    "hours of fasting": "h Durée du jeûne",
    "fasted": "jeûné",
    "Add your personal notes": "Note(s)",
    "Statistics": "Statistiques",
    "No data to show": "Pas de données à afficher",
    "Fastings + Targeted Hours": "Carême et intervalles de jeûne",
    "Download all data": "Charger toutes les données",
    "`Minimum Hours`-filter from s applied by default": "Le filtre \"nombre minimal d'heures\" est pris en compte.",
    "Fastings": "Intervalles",
    "Color Scheme": "Schéma de couleurs",
    "Settings": "Réglages",
    "Add notes after finished fasting": "Ajoute une note après le jeûne",
    "Load more": "Charger davantage",
    "E-Mail": "Courrier électronique",
    "Password": "Mot de passe",
    "Your Password": "Mot de passe utilisateur",
    "Forgot Password?": "Mot de passe oublié ?",
    "Your E-Mail": "Ton e-mail",
    "Password, min n characters": "Mot de passe avec au moins %{count} caractères",
    "Delete": "Supprimer",
    "Save": "Enregistrer",
    "Login": "Se connecter",
    "Signup": "Inscription",
    "Signup now": "Créer un compte utilisateur",
    "Logout": "Se déconnecter",
    "Open Webapp": "Ouvrir une webapp",
    "Notes": "Notes",
    "How much or Any notes?": "Combien / remarque ?",
    "Water": "Eau",
    "Tea": "Thé",
    "Coffee": "Café",
    "Salad": "Salade",
    "Vegetables": "Légumes",
    "Fruits": "Fruits",
    "Fish": "Poisson",
    "Meat + Chicken": "Viande",
    "Soup": "Soupe",
    "Bread + Wheat": "Pain + céréales complètes",
    "Fast-Food": "Fast-food",
    "Sweets": "Bonbons",
    "Meal": "Repas",
    "Alcohol": "Alcool",
    "Snack": "Snack",
    "Set Weight": "Saisir le poids",
    "Your Weight": "Ton poids",
    "Add some personal notes to your fasting": "Ajoute une note sur le jeûne",
    "lbs": "lbs",
    "kg": "kg",
    "Your fastings": "Jeûne passé",
    "Sure?": "Sûr ?",
    "true": "Oui",
    "false": "Non",
    "Offline": "Hors ligne",
    "Please check your internet connection and try again": "Veuillez vérifier votre connexion Internet et réessayer",
    "OK": "OK",
    "n % reached": "%{percentage}% atteint",
    "There is no more data": "Pas d'autres données disponibles",
    "Fasting and targeted hours": "Durée du jeûne et de l'intervalle",
    "Fasting hours only": "Durée du jeûne uniquement",
    "Current theme": "Système",
    "Opposite theme": "Inversement",
    "Back to Login": "Retour à la connexion",
    "Email": "Courrier électronique",
    "Phone number (optional)": "Facultatif : numéro de téléphone",
    "Appearance": "Schéma de couleurs",
    "Authorization failed": "Échec de l'autorisation",
    "Nutrition": "Alimentation",
    "Weight": "Poids",
    "Bread": "Pain",
    "Fast-food": "Fast-food",
    "Meat": "Viande",
    "Do you want to delete your account?": "Le compte d'utilisateur et toutes les données qui s'y rapportent doivent-ils être supprimés de manière irréversible ?",
    "Fasting Intervals": "Intervalles de jeûne",
    "There is no data in the given time area": "Aucune donnée disponible dans la période sélectionnée",
    "Change Password": "Modifier le mot de passe",
    "Error": "Erreur",
    "Your current password": "Mot de passe actuel",
    "Your new password (min. 8 characters)": "Nouveau mot de passe (au moins 8 caractères)",
    "After the password change you will have to login again": "Après la modification du mot de passe, il faut se reconnecter.",
    "Cancel": "Annuler",
    "Password changed successfully. Please login again": "Le mot de passe a été modifié. Veuillez vous reconnecter",
    "You should receive a mail with a password reset instructions": "Un e-mail de réinitialisation du mot de passe a été envoyé. Veuillez vérifier dans le dossier spam si aucun e-mail n'a été reçu.",
    "Invalid E-Mail": "Adresse e-mail non valide",
    "Not enough data": "Pas assez de données disponibles"
  }
}