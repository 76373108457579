{
  "es": {
    "Yeah, you reached your fasting goal": "Enhorabuena, ha alcanzado su objetivo de ayuno",
    "Your fasting will be completed": "El ayuno termina",
    "hour": "Hora",
    "hours": "Horas",
    "Hours": "Horas",
    "Days": "Días",
    "Fasting started at": "El ayuno comenzó en",
    "Started At": "Comenzó alrededor de",
    "Ended At": "Terminó en",
    "Password (at least 8 characters)": "Contraseña (mínimo 8 caracteres)",
    "Confirm Password": "Repite la contraseña",
    "Already having an account? Login here": "¿Ya tiene una cuenta? Acceda aquí",
    "Password must be at least n characters long": "La contraseña debe tener al menos %{count} caracteres.",
    "Please use a valid email": "Introduzca una dirección de correo electrónico correcta",
    "Please try another email": "Por favor, utilice una dirección de correo electrónico diferente",
    "Signup done! Please confirm your E-Mail and you are ready to use fastingMonster": "Registro exitoso. Por último, haga clic en el enlace del correo electrónico de confirmación y podrá utilizar fasting.monster.",
    "Add notes after fasting": "Nota después del ayuno anotar",
    "Saved changes": "Cambios guardados",
    "Targeted hours of fasting": "Duración prevista del ayuno",
    "InTotalXHoursFasted": "Total de <b>%{hoursOfFasting}</b> ayunadas",
    "Signup here if no account": "¿Aún no tiene cuenta de usuario? Regístrese aquí",
    "No more fastings found": "No se han encontrado más periodos de Cuaresma",
    "Nothing found. Maybe check filter?": "No se ha encontrado nada. Compruebe el filtro si es necesario",
    "Loading": "Se cargará",
    "Past Fastings": "Intervalos de ayuno",
    "Minimum Hours": "Número mínimo de horas",
    "All": "Todo",
    "n hours targeted": "%{targeted_hours} Horas previstas",
    "n hours fasted": "%{hours} horas de ayuno",
    "hours of fasting": "h Duración del ayuno",
    "fasted": "en ayunas",
    "Add your personal notes": "Nota(s)",
    "Statistics": "Estadísticas",
    "No data to show": "No hay datos que mostrar",
    "Fastings + Targeted Hours": "Cuaresma e intervalos de ayuno",
    "Download all data": "Cargar todos los datos",
    "`Minimum Hours`-filter from fastings is applied by default": "Se tiene en cuenta el número mínimo de horas de filtro",
    "Fastings": "Intervalos",
    "Color Scheme": "Esquema de colores",
    "Settings": "Ajustes",
    "Add notes after finished fasting": "Añadir nota después del ayuno",
    "Load more": "Cargar más",
    "E-Mail": "Correo electrónico",
    "Password": "Contraseña",
    "Your Password": "Contraseña del usuario",
    "Forgot Password?": "¿Ha olvidado su contraseña?",
    "Your E-Mail": "Su correo electrónico",
    "Password, min n characters": "Contraseña con al menos %{count} caracteres",
    "Delete": "Borrar",
    "Save": "Guardar",
    "Login": "Entrar en el sistema",
    "Signup": "Registro",
    "Signup now": "Crear una cuenta de usuario",
    "Logout": "Cerrar la sesión",
    "Open Webapp": "Abrir la aplicación web",
    "Notes": "Notas",
    "How much or Any notes?": "¿Cuánto / comentario?",
    "Water": "Agua",
    "Tea": "Té",
    "Coffee": "Café",
    "Salad": "Ensalada",
    "Vegetables": "Verduras",
    "Fruits": "Fruta",
    "Fish": "Pescado",
    "Meat + Chicken": "Carne",
    "Soup": "Sopa",
    "Bread + Wheat": "Pan y harina integral",
    "Fast-Food": "Comida rápida",
    "Sweets": "Dulces",
    "Meal": "Comida",
    "Alcohol": "Alcohol",
    "Snack": "Snack",
    "Set Weight": "Introduzca el peso",
    "Your Weight": "Su peso",
    "Add some personal notes to your fasting": "Añadir una nota sobre el ayuno",
    "lbs": "lbs",
    "kg": "kg",
    "Your fastings": "El ayuno pasado",
    "Sure?": "¿Seguro?",
    "true": "Sí",
    "false": "No",
    "Offline": "Fuera de línea",
    "Please check your internet connection and try again": "Por favor, compruebe su conexión a Internet y vuelva a intentarlo",
    "OK": "OK",
    "n % reached": "%{percentage}% alcanzado",
    "There is no more data": "No hay más datos disponibles",
    "Fasting and targeted hours": "Duración del ayuno y del intervalo",
    "Fasting hours only": "Sólo la duración del ayuno",
    "Current theme": "Sistema",
    "Opposite theme": "Invertido",
    "Back to Login": "Volver al inicio de sesión",
    "Email": "Correo electrónico",
    "Phone number (optional)": "Opcional: Número de teléfono",
    "Appearance": "Esquema de colores",
    "Authorization failed": "Autorización fallida",
    "Nutrition": "Nutrición",
    "Weight": "Peso",
    "Bread": "El pan",
    "Fast-food": "Comida rápida",
    "Meat": "Carne",
    "Do you want to delete your account?": "¿Deben eliminarse irremediablemente la cuenta de usuario y todos los datos asociados?",
    "Fasting Intervals": "Intervalos de ayuno",
    "There is no data in the given time area": "No hay datos disponibles en el periodo seleccionado",
    "Change Password": "Cambiar contraseña",
    "Error": "Error",
    "Your current password": "Contraseña actual",
    "Your new password (min. 8 characters)": "Nueva contraseña (mínimo 8 caracteres)",
    "After the password change you will have to login again": "Después de cambiar la contraseña, debe volver a conectarse.",
    "Cancel": "Cancelar",
    "Password changed successfully. Please login again": "La contraseña ha sido cambiada. Vuelva a conectarse",
    "You should receive a mail with a password reset instructions": "Se ha enviado un correo electrónico para restablecer la contraseña. Por favor, compruebe su carpeta de spam si no ha recibido ningún correo electrónico.",
    "Invalid E-Mail": "Dirección de correo electrónico no válida",
    "Not enough data": "No hay suficientes datos disponibles"
  }
}