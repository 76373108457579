import './app.css'

import { useTranslation } from 'react-i18next'

import { useEffect, useState } from 'react'
import { BrowserRouter, Link, Route, Routes, Navigate } from 'react-router-dom'
import clockIcon from '../icons/streamline/clock.svg'
import settingsIcon from '../icons/streamline/settings.svg'
import listIcon from '../icons/streamline/list.svg'
import intakeIcon from '../icons/streamline/coffee.svg'
import chartbarIcon from '../icons/streamline/chartbar.svg'
import * as api from './shared/api'
import Fastings from './components/Fastings'
import Login from './components/Login'
import Logout from './components/Logout'
import Settings from './components/Settings'
import Timer from './components/Timer'
import Signup from './components/Signup'
import Fasting from './components/Fasting'
import Intake from './components/Intake'
import Charts from './components/Charts'
import * as documentHelper from './shared/lib/document_helper'

import {
    removeAuthToken,
    loadApiSessionAndUserAndFastingData,
    getAuthToken,
} from './shared/session_and_user'

function App() {
    const { t } = useTranslation()
    const [userSettings, setUserSettings] = useState(null)
    const [user, setUser] = useState(null)
    const [currentFasting, setCurrentFasting] = useState(null)
    const [fastings, setFastings] = useState(null)
    const [connectionErrorMessage, setConnectionErrorMessage] = useState(null)
    const [onlyShowLogin, setOnlyShowLogin] = useState(false)

    let lastResponseError = null

    useEffect(() => {
        const clearSessionAndRedirectToLogin = async () => {
            await removeAuthToken()
            setUserSettings(null)
            setUser(null)
            setOnlyShowLogin(true)
            if (
                window.location.pathname !== '/login' &&
                window.location.pathname !== '/signup'
            ) {
                window.location = '/login'
            }
        }

        if (userSettings) {
            documentHelper.applyColorSchemeOnDocument(userSettings.color_scheme)
        }
        if (user === null) {
            loadApiSessionAndUserAndFastingData(api)
                .catch((err) => {
                    lastResponseError = err
                    console.error(err)
                    if (err.message.match('Network Error')) {
                        return setConnectionErrorMessage(err.message)
                    } else {
                        let message =
                            err.response &&
                            err.response.data &&
                            err.response.data.message
                                ? err.message + ': ' + err.response.data.message
                                : err.message
                        if (err.response.status === 401) {
                            alert('Please login again')
                            removeAuthToken().finally(() => {
                                setUserSettings(null)
                                setUser(null)
                            })
                            location.reload()
                            return
                        }
                        alert(message)
                    }
                    if (
                        err.reponse &&
                        (err.reponse.status !== 401 ||
                            err.reponse.status !== 403)
                    ) {
                        removeAuthToken().finally(() => {
                            setUserSettings(null)
                            setUser(null)
                        })
                    }
                })
                .then((data) => {
                    if (data) {
                        setUser(data.user)
                        setCurrentFasting(data.currentFasting)
                        setUserSettings(data.userSettings)
                    } else {
                        if (
                            lastResponseError &&
                            lastResponseError.reponse &&
                            (lastResponseError.reponse.status !== 401 ||
                                lastResponseError.reponse.status !== 403)
                        ) {
                            // force new login
                            clearSessionAndRedirectToLogin()
                            return
                        }
                    }

                    getAuthToken().then((authToken) => {
                        if (!authToken && !user) {
                            clearSessionAndRedirectToLogin()
                        }
                    })
                })
        }
    })

    if (onlyShowLogin) {
        return (
            <div className="no-bottom-navi">
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="login"
                            element={
                                <Login
                                    showLoginOnRestartingApp={setOnlyShowLogin}
                                />
                            }
                        />
                        <Route path="signup" element={<Signup />} />
                    </Routes>
                </BrowserRouter>
            </div>
        )
    } else if (userSettings) {
        return (
            <div>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Timer></Timer>} />
                        <Route
                            path="fastings"
                            element={<Fastings></Fastings>}
                        />
                        <Route
                            path="fastings/:fastingId"
                            element={<Fasting></Fasting>}
                        />
                        <Route path="intake" element={<Intake></Intake>} />
                        <Route path="charts" element={<Charts></Charts>} />
                        <Route
                            path="settings"
                            element={
                                <Settings
                                    userSettings={userSettings}
                                ></Settings>
                            }
                        />
                        <Route
                            path="signup"
                            element={<Navigate to="/" replace={true} />}
                        />
                        <Route
                            path="login"
                            element={<Navigate to="/" replace={true} />}
                        />
                        <Route path="logout" element={<Logout></Logout>} />
                    </Routes>
                    <nav id="bottom-navi">
                        <Link to="/">
                            <img src={clockIcon} alt="Settings" />
                        </Link>
                        <Link to="/fastings">
                            <img src={listIcon} alt="Fastings" />
                        </Link>
                        <Link to="/intake">
                            <img src={intakeIcon} alt="Intake" />
                        </Link>
                        <Link to="/charts">
                            <img src={chartbarIcon} alt="Graphs" />
                        </Link>
                        <Link to="/settings">
                            <img src={settingsIcon} alt="Settings" />
                        </Link>
                    </nav>
                </BrowserRouter>
            </div>
        )
    } else {
        return (
            <div id="loading-indicator">
                <div>{t('Loading')}...</div>
                {connectionErrorMessage && (
                    <div className="error-message">
                        {connectionErrorMessage}. {t('Service offline?')}
                    </div>
                )}
            </div>
        )
    }
}

export { App }
