// https://formidable.com/open-source/victory/gallery/victory-area-with-stroke

import { useEffect, useState } from 'react'
import {
    VictoryArea,
    VictoryChart,
    VictoryGroup,
    VictoryTooltip,
    VictoryVoronoiContainer,
    VictoryAxis,
} from 'victory'
import * as api from '../shared/api'
import './charts.css'

import { t, filterFastingsForChart } from '../shared/lib/helper'

import arrowLeft from '../../icons/streamline/arrow-left-data.svg'
import fileDownload from 'js-file-download'

function stripTimeFromDate(d) {
    return new Date(d).toLocaleDateString()
}

export default function Graphs() {
    const [timeSeries, setTimeSeries] = useState(null)
    const [targetedHours, setTargetedHours] = useState(null)
    const [chartViewMode, setChartViewMode] = useState('')
    const [earliestDate, setEarliestDate] = useState(null)
    const [latestDate, setLatestDate] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [nextPage, setNextPage] = useState(null)
    const [prevPage, setPrevPage] = useState(null)
    // this is only to used intially
    const [showGraph, setShowGraph] = useState(false)
    const [finishedWithLoadingData, setFinishedWithLoadingData] =
        useState(false)

    function filterAndSetFastingsForCharts(d) {
        const {
            fastings,
            targetedHours,
            currentPage,
            earliestDate,
            latestDate,
            nextPage,
            prevPage,
        } = filterFastingsForChart(d)

        setTimeSeries(fastings)
        setTargetedHours(targetedHours)
        setCurrentPage(currentPage)
        setEarliestDate(earliestDate)
        setLatestDate(latestDate)
        setNextPage(nextPage)
        setPrevPage(prevPage)
        setFinishedWithLoadingData(true)
        return { targets: targetedHours, fastings }
    }

    useEffect(() => {
        if (timeSeries) {
            return
        }
        api.fastingCharts({
            page: currentPage,
        }).then((res) => {
            filterAndSetFastingsForCharts(res.data)
            setShowGraph(true)
        })
    })

    async function download() {
        let response = await api.fastingsExport()
        let datetime = new Date()
            .toISOString()
            .replace(/:/g, '-')
            .replace(/\..*$/, '')
        let fallbackFilename = `fastings_${datetime}.xlsx`
        fileDownload(
            response.data,
            response.headers['content-disposition']
                ? response.headers['content-disposition'].replace(
                      /^.+?filename\=/,
                      ''
                  )
                : fallbackFilename
        )
    }

    async function displayNextPage() {
        if (!nextPage) {
            alert('There is no more data')
            return
        }
        await loadFastingData(Number(nextPage))
    }

    async function displayPreviousPage() {
        if (!prevPage) {
            alert('There is no more data')
            return
        }
        await loadFastingData(Number(prevPage))
    }

    async function loadFastingData(page) {
        setFinishedWithLoadingData(false)
        let res = await api.fastingCharts({
            page,
        })
        return filterAndSetFastingsForCharts(res.data)
    }

    function textColor() {
        return getComputedStyle(
            document.querySelector('body')
        ).getPropertyValue('--color-text')
    }

    function backgroundColor() {
        return getComputedStyle(
            document.querySelector('body')
        ).getPropertyValue('--color-white')
    }

    function chartTheme() {
        return {
            axis: {
                style: {
                    tickLabels: {
                        fill: textColor(),
                        opacity: 0.75,
                        fontSize: 15,
                        padding: 10,
                    },
                    grid: {
                        stroke: textColor(),
                        // strokeDasharray: '7',
                        opacity: 0.05,
                    },
                    axis: {
                        stroke: textColor(),
                        opacity: 0.5,
                    },
                    axisLabel: {
                        fontSize: 15,
                        fill: textColor(),
                        opacity: 0.5,
                        padding: 30,
                    },
                },
            },
        }
    }

    return (
        <div
            className={'charts ' + (!finishedWithLoadingData ? 'loading' : '')}
        >
            <header>
                <img
                    src={arrowLeft}
                    className={'arrow-left' + (!nextPage ? ' inactive' : '')}
                    onClick={(e) => displayNextPage()}
                ></img>
                <img
                    src={arrowLeft}
                    className={'arrow-right' + (!prevPage ? ' inactive' : '')}
                    onClick={(e) => displayPreviousPage()}
                ></img>
                <h1>{t('Statistics')}</h1>
                {(earliestDate || latestDate) && (
                    <h5>
                        {earliestDate ? stripTimeFromDate(earliestDate) : ''} -{' '}
                        {latestDate ? stripTimeFromDate(latestDate) : ''}
                    </h5>
                )}
            </header>
            {(!timeSeries || timeSeries.length === 0) && (
                <div className="no-data-found">
                    {!timeSeries ? t('Loading') : t('No data to show')}
                </div>
            )}
            {showGraph && timeSeries?.length > 0 && targetedHours?.length > 0 && (
                <VictoryChart
                    // width={400}
                    height={300}
                    containerComponent={<VictoryVoronoiContainer />}
                    theme={chartTheme()}
                    //animate={{duration: 500}}
                >
                    <VictoryGroup
                        style={{ data: { strokeWidth: 3, fillOpacity: 0.4 } }}
                    >
                        <VictoryArea
                            style={{
                                data: {
                                    fill:
                                        chartViewMode === 'fasting+targeted'
                                            ? 'cyan'
                                            : 'transparent',
                                    stroke:
                                        chartViewMode === 'fasting+targeted'
                                            ? 'cyan'
                                            : 'transparent',
                                },
                                labels: { display: 'none' },
                            }}
                            data={targetedHours}
                            x="date"
                            y="hours"
                        />

                        <VictoryArea
                            style={{
                                data: { fill: 'magenta', stroke: 'magenta' },
                            }}
                            data={timeSeries}
                            labelComponent={
                                <VictoryTooltip
                                    style={{ fill: textColor() }}
                                    pointerLength={0}
                                    flyoutStyle={{
                                        strokeWidth: 0,
                                        fill: backgroundColor(),
                                        opacity: 0.75,
                                    }}
                                    flyoutPadding={{
                                        top: 2,
                                        bottom: 2,
                                        left: 5,
                                        right: 5,
                                    }}
                                />
                            }
                            x="date"
                            y="hours"
                        />
                        <VictoryAxis
                            dependentAxis
                            label="hours"
                            style={{
                                tickLabels: {
                                    fill: 'white', //CHANGE COLOR OF Y-AXIS LABELS
                                },
                            }}
                        />
                    </VictoryGroup>
                </VictoryChart>
            )}

            <div className="settings">
                <label htmlFor="view-mode">
                    <select
                        className="select"
                        name="view-mode"
                        onChange={(e) => setChartViewMode(e.target.value)}
                    >
                        <option value="">{t('Fastings')}</option>
                        <option value="fasting+targeted">
                            {t('Fastings + Targeted Hours')}
                        </option>
                    </select>
                </label>
            </div>
            <div className="download text-center">
                <a className="button small" onClick={download}>
                    {t('Download all data')}
                </a>
            </div>
            <div className="small-and-gentle-hint">
                {t(
                    '`Minimum Hours`-filter from fastings is applied by default'
                )}
            </div>
        </div>
    )
}
