{
  "en": {
    "hour": "hour",
    "hours": "hours",
    "Hours": "Hours",
    "Days": "Days",
    "Signup here if no account": "No Account, yet? Signup here",
    "Already having an account? Login here": "Already having an account? Login here",
    "Fasting started at": "Fasting started at",
    "Started At": "Started at",
    "Ended At": "Ended at",
    "Yeah, you reached your fasting goal": "Yeah, you reached your fasting goal",
    "Your fasting will be completed": "Your fasting will be completed",
    "Password (at least 8 characters)": "Password (at least 8 characters)",
    "Confirm Password": "Confirm Password",
    "Password must be at least n characters long": "Password must be at least %{count} characters long",
    "Please use a valid email": "Please use a valid email",
    "Please try another email": "Please try another email",
    "Signup done! Please confirm your E-Mail and you are ready to use fastingMonster": "Signup done! Please confirm your E-Mail and you are ready to use fasting.monster",
    "Add notes after fasting": "Add notes after fasting",
    "Saved changes": "Saved changes",
    "Targeted hours of fasting": "Targeted hours of fasting",
    "InTotalXHoursFasted": "In total: <b>%{hoursOfFasting}</b> fasted",
    "No more fastings found": "No more fastings found",
    "Nothing found. Maybe check filter?": "Nothing found. Maybe check filter?",
    "Loading": "Loading",
    "Past Fastings": "Past Fastings",
    "Minimum Hours": "Minimum Hours",
    "All": "All",
    "n hours targeted": "%{targeted_hours} hours targeted",
    "n hours fasted": "%{hours} hours fasted",
    "hours of fasting": "hours of fasting",
    "fasted": "fasted",
    "Add your personal notes": "Add your personal notes",
    "Statistics": "Statistics",
    "No data to show": "No data to show",
    "Fastings + Targeted Hours": "Fastings + Targeted Hours",
    "Download all data": "Download all Fastings",
    "`Minimum Hours`-filter from fastings is applied by default": "`Minimum Hours`-filter from fastings is applied by default",
    "Fastings": "Fastings",
    "Settings": "Settings",
    "Add notes after finished fasting": "Add notes after finished fasting",
    "Load more": "Load more",
    "E-Mail": "E-Mail",
    "Password": "Password",
    "Your Password": "Your Password",
    "Forgot Password?": "Forgot Password?",
    "Your E-Mail": "Your E-Mail",
    "Password, min n characters": "Password, min. %{count} characters",
    "Delete": "Delete",
    "Save": "Save",
    "Notes": "Notes",
    "Login": "Login",
    "Signup": "Signup",
    "Signup now": "Signup",
    "Logout": "Logout",
    "Open Webapp": "Open Webapp",
    "How much or Any notes?": "How much / Any notes?",
    "Water": "Water",
    "Tea": "Tea",
    "Coffee": "Coffee",
    "Salad": "Salad",
    "Vegetables": "Vegetables",
    "Fruits": "Fruits",
    "Fish": "Fish",
    "Meat + Chicken": "Meat + Chicken",
    "Soup": "Soup",
    "Bread + Wheat": "Bread + Wheat",
    "Fast-Food": "Fast-Food",
    "Sweets": "Sweets",
    "Meal": "Meal",
    "Alcohol": "Alcohol",
    "Snack": "Snack",
    "Set Weight": "Set Weight",
    "Your Weight": "Your Weight",
    "Add some personal notes to your fasting": "Add some personal notes to your fasting",
    "lbs": "lbs",
    "kg": "kg",
    "Your fastings": "Your fastings",
    "Sure?": "Sure?",
    "Yes": "Yes",
    "No": "No",
    "Offline": "Offline",
    "Please check your internet connection and try again": "Please check your internet connection and try again",
    "OK": "OK",
    "n % reached": "%{percentage}% reached",
    "There is no more data": "There is no more data",
    "Fasting and targeted hours": "Fasting and targeted hours",
    "Fasting hours only": "Fasting hours only",
    "Current theme": "Default",
    "Opposite theme": "Opposite",
    "Back to Login": "Back to Login",
    "Email": "E-Mail",
    "Phone number (optional)": "Optional: Phone number",
    "Appearance": "Color Appearance",
    "Authorization failed": "Authorization failed",
    "Nutrition": "Nutrition",
    "Weight": "Weight",
    "Bread": "Bread",
    "Fast-food": "Fast-Food",
    "Meat": "Meat",
    "Do you want to delete your account?": "Do you really want to delete your account and all it's data irreversible?",
    "Fasting Intervals": "Fasting Intervals",
    "There is no data in the given time area": "There is no data in the given time area",
    "Change Password": "Change Password",
    "Error": "Error",
    "Your current password": "Your current password",
    "Your new password (min. 8 characters)": "Your new password (min. 8 characters)",
    "After the password change you will have to login again": "After the password change you will have to login again",
    "Cancel": "Cancel",
    "Password changed successfully. Please login again": "Password changed successfully. Please login again",
    "You should receive a mail with a password reset instructions": "You should receive a mail with a password reset instruction. Please check spam if nothing is found",
    "Invalid E-Mail": "Invalid E-Mail",
    "Not enough data": "Not enough data"
  }
}