import './timer.css'

import { useEffect, useState } from 'react'
import * as helper from '../shared/lib/helper'
import { t } from '../shared/lib/helper'
import { useInterval } from 'usehooks-ts'
import { diffInSeconds, calculateAndDisplayTimer } from '../shared/lib/timer'

import CircularProgressBar from './CircularProgressBar'

import * as api from '../shared/api'

export default function Timer() {
    const setBreakfastTimeFromFasting = (fasting) => {
        return fasting
            ? helper.addHumanizedAttributesToFasting(fasting)
                  .target_end_humanize
            : '…'
    }

    const fastingIntervals = [12, 13, 14, 15, 16, 18, 20, 24, 48, 72, 120]

    const [isActive, setActive] = useState(null)
    const [interval, setInterval] = useState(null)
    const [isLoadingCurrentFasting, setIsLoadingCurrentFasting] = useState(null)
    const [currentFasting, setCurrentFasting] = useState(null)
    const [hours, setHours] = useState('--')
    const [minutes, setMinutes] = useState('--')
    const [seconds, setSeconds] = useState('--')
    const [userSettings, setUserSettings] = useState({})

    const [directionUnit, setDirectionUnit] = useState('')
    const [breakfastTime, setBreakfastTime] = useState('')

    const isFastingCompleted = () =>
        currentFasting
            ? diffInSeconds(currentFasting.starts_at, interval) < 0
            : null

    useInterval(() => {
        if (currentFasting) {
            let startsAt = currentFasting.starts_at
            let time = calculateAndDisplayTimer(
                startsAt,
                interval,
                userSettings.timer_counting
            )

            if (diffInSeconds(startsAt, interval) < 0) {
                setDirectionUnit(time[0])
            } else {
                setDirectionUnit('')
            }
            setMinutes(time[2])
            setHours(time[1])
            setSeconds(time[3])
            setInterval(currentFasting.targeted_hours)
        } else {
            setDirectionUnit('')
            setMinutes('00')
            setHours(Number(interval) < 10 ? '0' + Number(interval) : interval)
            setSeconds('00')
        }
    }, 200)

    useInterval(async () => {
        // poll every 10 seconds to get status update
        if (isLoadingCurrentFasting) {
            return
        }
        try {
            await loadCurrentFastingAndUpdateStates()
        } catch (e) {
            console.error(e)
        }
    }, 10000)

    function isCustomInterval() {
        return interval ? !fastingIntervals.includes(interval) : false
    }

    async function setCustomInterval() {
        let interval = prompt('Your fasting interval in hours')
        interval = interval ? Number(interval) : null
        if (!interval || interval < 1) {
            return alert(
                'Your interval can only be numeric and at least 1. Please try again.'
            )
        }
        await setIntervalFromButton(Math.round(Math.abs(interval)))
    }

    async function toggleDirection() {
        let newDirection =
            userSettings.timer_counting === 'remaining'
                ? 'processed'
                : 'remaining'
        // show instant a change in the UI
        setUserSettings({
            ...userSettings,
            ...{ timer_counting: newDirection },
        })
        await api.updateUserSettings({
            timer_counting: newDirection,
        })
        await loadUserSettings()
    }

    async function setIntervalFromButton(i) {
        // show instant a change in the UI
        setInterval(i)
        await api.updateCurrentFasting({ targeted_hours: i })
        await loadCurrentFastingAndUpdateStates()
        await api.updateUserSettings({ interval_hours: i })
        setInterval(i)
    }

    async function loadUserSettings() {
        let userRes = await api.user()
        setUserSettings(JSON.parse(userRes.data.data.settings))
    }

    async function loadCurrentFastingAndUpdateStates() {
        setIsLoadingCurrentFasting(true)
        let res = await api.currentFasting()
        setIsLoadingCurrentFasting(false)

        if (res.data && Object.keys(res.data.data).length > 0) {
            setCurrentFasting(res.data.data)
            setBreakfastTime(setBreakfastTimeFromFasting(res.data.data))
            setInterval(res.data.data.targeted_hours)
            setActive(true)
            await loadUserSettings()
        } else {
            if (!interval) {
                await loadUserSettings()
                setInterval(userSettings.interval_hours)
            }
            resetTimer()
        }
    }

    async function changeStartTime(e) {
        if (!currentFasting) {
            return
        }
        let { value } = e.target
        if (value.match(/^\d+\:\d+/)) {
            // adds new time to current date with seconds :00
            value =
                currentFasting.starts_at.replace(/T\d+.*$/, 'T' + value) + ':00'
        }
        await api.updateCurrentFasting({
            starts_at: new Date(value).toISOString(),
        })
        await loadCurrentFastingAndUpdateStates()
    }

    function resetTimer() {
        setCurrentFasting(null)
        setActive(false)
        setBreakfastTime('')
        loadUserSettings()
        setDirectionUnit('')
        setHours(interval || '00')
        setMinutes('00')
        setSeconds('00')
    }

    async function toggleStartStop() {
        let newIsActiveState = !isActive

        if (
            userSettings.add_notes_after_finished_fasting &&
            !newIsActiveState
        ) {
            // this is done async, so that the prompt instantly appears
            api.toggleFasting({ targeted_hours: interval })
            let note = prompt(t('Add your personal notes'))
            if (note && note.trim()) {
                await api.updateLastFasting({ note: note.trim() })
            }
        } else {
            await api.toggleFasting({ targeted_hours: interval })
        }
        await loadCurrentFastingAndUpdateStates()

        if (!newIsActiveState) {
            resetTimer()
        }
    }

    function toggleShowDateTimeInput(e) {
        if (e.target.tagName !== 'DIV') {
            return
        }
        let el = e.target.closest('.starting-time')
            ? e.target.closest('.starting-time')
            : e.target
        el.classList.toggle('show-date-time-input')
    }

    useEffect(() => {
        // only load initially
        if (!currentFasting && !interval) {
            loadCurrentFastingAndUpdateStates()
        }
    }, [currentFasting, interval])

    return (
        <div
            className={
                isActive
                    ? 'timer full-height-and-centered active'
                    : 'timer full-height-and-centered'
            }
        >
            {!hours || hours === '--' ? (
                <div className="circular-progress-bar-placeholder"></div>
            ) : (
                <div>
                    <div
                        className="circular-progress-bar"
                        onClick={toggleDirection}
                    >
                        <CircularProgressBar
                            percent={
                                currentFasting
                                    ? Math.round(
                                          (100 / interval) *
                                              (interval -
                                                  diffInSeconds(
                                                      currentFasting.starts_at,
                                                      interval
                                                  ) /
                                                      3600)
                                      )
                                    : 0
                            }
                            direction={userSettings.timer_counting}
                            counter={[directionUnit, hours, minutes, seconds]}
                        ></CircularProgressBar>
                    </div>
                </div>
            )}
            <div className="breakfast-time">
                {isActive && !isFastingCompleted() && (
                    <div className="fasting-elapsing-time-humanzied">
                        {t('Your fasting will be completed')}{' '}
                        <div className="time">{breakfastTime}</div>
                    </div>
                )}
                {isActive && isFastingCompleted() && (
                    <div className="fasting-elapsing-time-humanzied completed">
                        {t('Yeah, you reached your fasting goal')} 🎉
                    </div>
                )}
            </div>
            <div className="starting-time" onClick={toggleShowDateTimeInput}>
                <div>{t('Fasting started at')}</div>
                <input
                    type="time"
                    step="60"
                    value={
                        currentFasting
                            ? helper.timeZonedTimeFromIsoDateString(
                                  currentFasting.starts_at
                              )
                            : '00:00:00'
                    }
                    onChange={changeStartTime}
                />
                <input
                    type="datetime-local"
                    value={
                        currentFasting
                            ? helper.dateIsoToDateTimeLocalFormat(
                                  currentFasting.starts_at
                              )
                            : ''
                    }
                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                    onChange={changeStartTime}
                />
            </div>
            <div className="start-stop-button">
                <button className="button" onClick={toggleStartStop}>
                    {isActive ? t('Stop') : t('Start')}
                </button>
            </div>
            <div className="fasting-intervals">
                <ul>
                    <li
                        className={
                            'button' + (isCustomInterval() ? ' active' : '')
                        }
                        key={isCustomInterval() ? interval : 'custom-interval'}
                        data-interval={
                            isCustomInterval() ? interval : 'custom-interval'
                        }
                        onClick={setCustomInterval}
                    >
                        {isCustomInterval() ? interval : 'X'} {t('Hours')}
                    </li>
                    {fastingIntervals.map((i) => (
                        <li
                            className={
                                i === interval ? 'button active' : 'button'
                            }
                            data-interval={i}
                            key={i}
                            onClick={(e) => setIntervalFromButton(i)}
                        >
                            <span className="interval">
                                {i >= 72 ? Math.ceil(i / 24) : i}
                            </span>
                            <span className="unit">
                                {i >= 72 ? t('Days') : t('Hours')}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
