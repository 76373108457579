import { setTranslations, setLocale } from 'react-i18nify'
import { en } from './en.json'
import { de } from './de.json'
import { fr } from './fr.json'
import { es } from './es.json'
import { it } from './it.json'

const debug =
    process?.env?.NODE_ENV === 'development' ||
    window?.location?.host?.startsWith('localhost')

const lang = (navigator ? navigator.language : 'en').replace(/(\-.+?)$/, '')

const translations = {
    de,
    en,
    fr,
    es,
    it,
}

if (debug) {
    console.debug(`Selected language (i18nify): ${lang}`)
    console.debug(`Translations (i18nify)`, translations)
}

setTranslations(translations)
setLocale(translations[lang] ? lang : 'en')

// https://dev.to/adrai/how-to-properly-internationalize-a-react-application-using-i18next-3hdb
