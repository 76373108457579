export function applyColorSchemeOnDocument(colorScheme) {
    document.querySelector('body').classList.remove('force-light-color-scheme')
    document.querySelector('body').classList.remove('force-dark-color-scheme')
    if (colorScheme === 'dark' || colorScheme === 'light') {
        document
            .querySelector('body')
            .classList.add(`force-${colorScheme}-color-scheme`)
    } else if (colorScheme !== 'auto') {
        console.warn(`ColorScheme can only be dark, light or auto`)
    }
}
