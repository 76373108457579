import * as api from '../shared/api'

import './fasting.css'

import closeIcon from '../../icons/streamline-micro-line/close.svg'

import {
    numberWithFraction,
    hoursUnit,
    fastingHours,
    dateIsoToDateTimeLocalFormat,
    t,
} from '../shared/lib/helper'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

function Fasting() {
    const { fastingId } = useParams()
    const navigate = useNavigate()
    const [fasting, setFasting] = useState(null)
    const [formMessage, setFormMessage] = useState(null)
    const [hoursOfFasting, setHoursOfFasting] = useState(null)

    useEffect(() => {
        if (!fasting) {
            loadFasting()
        }
    })

    async function loadFasting() {
        let res = await api.fasting(fastingId)
        setFasting(res.data.data)
        onChangeDates(res.data.data)
    }

    async function handleSubmit(event) {
        // update
        const formData = new FormData(event.currentTarget)
        event.preventDefault()
        let data = {}
        for (let [key, value] of formData.entries()) {
            data[key] = value
        }
        await api.updateFasting(fasting.id, data)
        setFormMessage(t('Saved changes'))
        setTimeout(() => setFormMessage(null), 2000)
        await loadFasting()
    }

    async function deleteRecord(e) {
        let id = fasting.id
        await api.deleteFasting(id)
        navigate('/fastings')
        setFasting(null)
    }

    function onChangeDates(f = null) {
        if (!f) {
            f = fasting
        }
        setHoursOfFasting(
            numberWithFraction(fastingHours(f)) +
                ' ' +
                hoursUnit(fastingHours(f))
        )
    }

    return (
        <div className="fasting">
            {fasting && (
                <div className="data">
                    <form className="form" onSubmit={handleSubmit}>
                        <Link to="/fastings" className="close">
                            <img src={closeIcon} alt="Back" />
                        </Link>

                        <label htmlFor="started_at">
                            <div>{t('Started At')}</div>
                            <input
                                type="datetime-local"
                                name="starts_at"
                                defaultValue={dateIsoToDateTimeLocalFormat(
                                    fasting.starts_at
                                )}
                            />
                        </label>
                        <label htmlFor="ends_at">
                            <div>{t('Ended At')}</div>
                            <input
                                type="datetime-local"
                                name="ends_at"
                                defaultValue={dateIsoToDateTimeLocalFormat(
                                    fasting.ends_at
                                )}
                            />
                        </label>
                        <label htmlFor="targeted_hours">
                            <div>{t('Targeted hours of fasting')}</div>
                            <input
                                type="number"
                                name="targeted_hours"
                                min="1"
                                step="1"
                                defaultValue={fasting.targeted_hours}
                            />
                        </label>
                        <label htmlFor="note">
                            <div>{t('Notes')}</div>
                            <textarea
                                name="note"
                                defaultValue={fasting.note}
                                style={{ minHeight: '5em' }}
                            ></textarea>
                        </label>
                        <label>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t('InTotalXHoursFasted', {
                                        hoursOfFasting,
                                    }),
                                }}
                            ></div>
                        </label>
                        <a
                            className="button delete"
                            onClick={(e) => deleteRecord(e)}
                        >
                            {t('Delete')}
                        </a>
                        <button className="button" role="submit">
                            {t('Save')}
                        </button>
                        {formMessage && (
                            <div className="form-message">{formMessage}</div>
                        )}
                    </form>
                </div>
            )}
        </div>
    )
}

export default Fasting
