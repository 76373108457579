import * as api from '../shared/api'
import { t } from '../shared/lib/helper'

import './intake.css'

import waterSVG from '../../icons/streamline/intakes/water.svg'
import teaSVG from '../../icons/streamline/intakes/tea.svg'
import coffeeSVG from '../../icons/streamline/intakes/coffee.svg'
import saladSVG from '../../icons/streamline/intakes/salad.svg'
import vegetablesSVG from '../../icons/streamline/intakes/vegetables.svg'
import fruitsSVG from '../../icons/streamline/intakes/fruits.svg'
import fishSVG from '../../icons/streamline/intakes/fish.svg'
import meatSVG from '../../icons/streamline/intakes/meat.svg'
import breadSVG from '../../icons/streamline/intakes/bread.svg'
import fastFoodSVG from '../../icons/streamline/intakes/fast-food.svg'
import sweetsSVG from '../../icons/streamline/intakes/sweets.svg'
import mealSVG from '../../icons/streamline/intakes/meal.svg'
import alcoholSVG from '../../icons/streamline/intakes/alcohol.svg'
import snackSVG from '../../icons/streamline/intakes/cheese.svg'
import soupSVG from '../../icons/streamline/intakes/soup.svg'
import dietScaleSVG from '../../icons/streamline/diet-scale.svg'

function Intake() {
    async function handleIntakeClick(e) {
        let li = e.target.closest('li[data-category') || e.target
        let which = li.dataset.category
        let lastIntakeNote = await api.lastIntakeNote(which)
        let note = prompt('How much / Any notes?', lastIntakeNote || '')
        if (note && note.trim()) {
            await api.addIntake(which, note.trim())
        }
    }

    async function handleSetWeightClick(e) {
        let weight = await api.lastWeight()
        weight = prompt('Your weight', weight || '')
        if (weight) {
            await api.setWeight(weight)
        }
    }

    return (
        <div className="intake">
            <ul className="icons">
                <li data-category="water" onClick={(e) => handleIntakeClick(e)}>
                    <img src={waterSVG}></img>
                    <h6>{t('Water')}</h6>
                </li>
                <li data-category="tea" onClick={(e) => handleIntakeClick(e)}>
                    <img src={teaSVG}></img>
                    <h6>{t('Tea')}</h6>
                </li>
                <li
                    data-category="coffee"
                    onClick={(e) => handleIntakeClick(e)}
                >
                    <img src={coffeeSVG}></img>
                    <h6>{t('Coffee')}</h6>
                </li>
                <li data-category="salad" onClick={(e) => handleIntakeClick(e)}>
                    <img src={saladSVG}></img>
                    <h6>{t('Salad')}</h6>
                </li>
                <li
                    data-category="vegetables"
                    onClick={(e) => handleIntakeClick(e)}
                >
                    <img src={vegetablesSVG}></img>
                    <h6>{t('Vegetables')}</h6>
                </li>
                <li
                    data-category="fruits"
                    onClick={(e) => handleIntakeClick(e)}
                >
                    <img src={fruitsSVG}></img>
                    <h6>{t('Fruits')}</h6>
                </li>
                <li data-category="fish" onClick={(e) => handleIntakeClick(e)}>
                    <img src={fishSVG}></img>
                    <h6>{t('Fish')}</h6>
                </li>
                <li data-category="meat" onClick={(e) => handleIntakeClick(e)}>
                    <img src={meatSVG}></img>
                    <h6>{t('Meat + Chicken')}</h6>
                </li>
                <li data-category="soup" onClick={(e) => handleIntakeClick(e)}>
                    <img src={soupSVG}></img>
                    <h6>{t('Soup')}</h6>
                </li>
                <li data-category="bread" onClick={(e) => handleIntakeClick(e)}>
                    <img src={breadSVG}></img>
                    <h6>{t('Bread + Wheat')}</h6>
                </li>
                <li
                    data-category="fast-food"
                    onClick={(e) => handleIntakeClick(e)}
                >
                    <img src={fastFoodSVG}></img>
                    <h6>{t('Fast-Food')}</h6>
                </li>
                <li
                    data-category="sweets"
                    onClick={(e) => handleIntakeClick(e)}
                >
                    <img src={sweetsSVG}></img>
                    <h6>{t('Sweets')}</h6>
                </li>
                <li data-category="meal" onClick={(e) => handleIntakeClick(e)}>
                    <img src={mealSVG}></img>
                    <h6>{t('Meal')}</h6>
                </li>
                <li
                    data-category="alcohol"
                    onClick={(e) => handleIntakeClick(e)}
                >
                    <img src={alcoholSVG}></img>
                    <h6>{t('Alcohol')}</h6>
                </li>
                <li data-category="snack" onClick={(e) => handleIntakeClick(e)}>
                    <img src={snackSVG}></img>
                    <h6>{t('Snack')}</h6>
                </li>
            </ul>
            <div className="weight-box">
                <div
                    className="weight"
                    onClick={(e) => handleSetWeightClick(e)}
                >
                    <img src={dietScaleSVG} alt="Add Weight"></img>
                    <h6>{t('Set Weight')}</h6>
                </div>
            </div>
        </div>
    )
}

export default Intake
