// https://formidable.com/open-source/victory/gallery/animating-circular-progress-bar

import { useState, useEffect } from 'react'
import { VictoryAnimation, VictoryLabel, VictoryPie } from 'victory'
import { cssVariableValueFromDocument, t } from '../shared/lib/helper'

export default function CircularProgressBar(props) {
    const [progressData, setProgressData] = useState(null)
    const [direction, setDirection] = useState(null)

    function dataFor(percent) {
        return [
            { x: 1, y: percent },
            { x: 2, y: 100 - percent },
        ]
    }

    useEffect(() => {
        if (direction !== props.direction) {
            setDirection(props.direction)
        }
        if (
            !progressData ||
            props.counter.join('') !== progressData.counter.join('')
        ) {
            setProgressData({
                percent: Number(props.percent),
                data: dataFor(Number(props.percent)),
                counter: props.counter,
            })
        }
    })

    return (
        <div>
            <svg viewBox="0 0 400 400" width="100%" height="100%">
                <VictoryPie
                    standalone={false}
                    //animate={{ duration: 200 }}
                    width={400}
                    height={400}
                    data={progressData?.data || []}
                    innerRadius={130}
                    // cornerRadius={25}
                    labels={() => null}
                    style={{
                        data: {
                            fill: ({ datum }) => {
                                let fillColor = cssVariableValueFromDocument(
                                    '--color-primary-alt'
                                )
                                let emptyColor =
                                    cssVariableValueFromDocument(
                                        '--color-grey-light'
                                    )
                                // swap colors of counter mode is remaining
                                if (direction === 'remaining') {
                                    fillColor =
                                        cssVariableValueFromDocument(
                                            '--color-grey-light'
                                        )
                                    emptyColor = cssVariableValueFromDocument(
                                        '--color-primary-alt'
                                    )
                                }
                                return datum.x === 1 ? fillColor : emptyColor
                            },
                        },
                    }}
                />
                <VictoryAnimation duration={100} data={progressData}>
                    {(newProps) => {
                        if (!progressData) {
                            return null
                        }
                        return (
                            <VictoryLabel
                                textAnchor="middle"
                                verticalAnchor="middle"
                                x={200}
                                y={200}
                                text={
                                    progressData.counter[0] +
                                    '' +
                                    progressData.counter.slice(1).join(':')
                                }
                                style={{
                                    fontSize: 45,
                                    fontFamily: cssVariableValueFromDocument(),
                                    fontWeight: 'bold',
                                    fill: cssVariableValueFromDocument(
                                        '--color-text'
                                    ),
                                }}
                            />
                        )
                    }}
                </VictoryAnimation>
            </svg>
        </div>
    )
}
