{
  "it": {
    "Yeah, you reached your fasting goal": "Congratulazioni, avete raggiunto il vostro obiettivo di digiuno",
    "Your fasting will be completed": "Il digiuno termina",
    "hour": "Ora",
    "hours": "Orario",
    "Hours": "Orario",
    "Days": "Giorni",
    "Fasting started at": "Il digiuno è iniziato alle",
    "Started At": "Iniziato intorno a",
    "Ended At": "Terminato a",
    "Password (at least 8 characters)": "Password (min. 8 caratteri)",
    "Confirm Password": "Ripetere la password",
    "Already having an account? Login here": "Avete già un account? Accedi qui",
    "Password must be at least n characters long": "La password deve essere lunga almeno %{count} caratteri.",
    "Please use a valid email": "Inserire un indirizzo e-mail corretto",
    "Please try another email": "Si prega di utilizzare un indirizzo e-mail diverso",
    "Signup done! Please confirm your E-Mail and you are ready to use fastingMonster": "La registrazione è andata a buon fine. Infine, cliccate sul link contenuto nell'e-mail di conferma e potrete utilizzare fasting.monster.",
    "Add notes after fasting": "Nota dopo il digiuno scrivere",
    "Saved changes": "Modifiche salvate",
    "Targeted hours of fasting": "Durata prevista del digiuno",
    "InTotalXHoursFasted": "Totale <b>%{hoursOfFasting}</b> di digiuno",
    "Signup here if no account": "Non c'è ancora un account utente? Registrati qui",
    "No more fastings found": "Non sono stati trovati altri periodi di quaresima",
    "Nothing found. Maybe check filter?": "Non è stato trovato nulla. Controllare il filtro, se necessario",
    "Loading": "Verrà caricato",
    "Past Fastings": "Intervalli di digiuno",
    "Minimum Hours": "Numero minimo di ore",
    "All": "Tutti",
    "n hours targeted": "%{targeted_hours} Ore previste",
    "n hours fasted": "%{hours} ore a digiuno",
    "hours of fasting": "h Durata del digiuno",
    "fasted": "digiunato",
    "Add your personal notes": "Nota(e)",
    "Statistics": "Statistiche",
    "No data to show": "Nessun dato da visualizzare",
    "Fastings + Targeted Hours": "Quaresima e intervalli di digiuno",
    "Download all data": "Caricare tutti i dati",
    "`Minimum Hours`-filter from fastings is applied by default": "Si tiene conto del numero minimo di ore di filtraggio",
    "Fastings": "Intervalli",
    "Color Scheme": "Schema di colore",
    "Settings": "Impostazioni",
    "Add notes after finished fasting": "Aggiungere una nota dopo il digiuno",
    "Load more": "Per saperne di più",
    "E-Mail": "E-mail",
    "Password": "Password",
    "Your Password": "Password utente",
    "Forgot Password?": "Password dimenticata?",
    "Your E-Mail": "Il tuo indirizzo e-mail",
    "Password, min n characters": "Password con almeno %{count} caratteri",
    "Delete": "Cancellare",
    "Save": "Risparmiare",
    "Login": "Accedi",
    "Signup": "Registrazione",
    "Signup now": "Creare un account utente",
    "Logout": "Disconnettersi",
    "Open Webapp": "Aprire la webapp",
    "Notes": "Note",
    "How much or Any notes?": "Quanto / commento?",
    "Water": "Acqua",
    "Tea": "Tè",
    "Coffee": "Caffè",
    "Salad": "Insalata",
    "Vegetables": "Verdure",
    "Fruits": "La frutta",
    "Fish": "Pesce",
    "Meat + Chicken": "Carne",
    "Soup": "Zuppa",
    "Bread + Wheat": "Pane + integrale",
    "Fast-Food": "Fast food",
    "Sweets": "Dolci",
    "Meal": "Pasto",
    "Alcohol": "Alcool",
    "Snack": "Spuntino",
    "Set Weight": "Inserire il peso",
    "Your Weight": "Il vostro peso",
    "Add some personal notes to your fasting": "Aggiungere una nota sul digiuno",
    "lbs": "libbre",
    "kg": "kg",
    "Your fastings": "Digiuno passato",
    "Sure?": "Sei sicuro?",
    "true": "Sì",
    "false": "No",
    "Offline": "Non in linea",
    "Please check your internet connection and try again": "Controllare la connessione a Internet e riprovare",
    "OK": "OK",
    "n % reached": "%{percentage}% raggiunta",
    "There is no more data": "Non sono disponibili altri dati",
    "Fasting and targeted hours": "Durata del digiuno e degli intervalli",
    "Fasting hours only": "Solo la durata del digiuno",
    "Current theme": "Sistema",
    "Opposite theme": "Invertito",
    "Back to Login": "Torna al login",
    "Email": "E-mail",
    "Phone number (optional)": "Facoltativo: numero di telefono",
    "Appearance": "Schema di colore",
    "Authorization failed": "Autorizzazione fallita",
    "Nutrition": "Alimentazione",
    "Weight": "Peso",
    "Bread": "Pane",
    "Fast-food": "Fast food",
    "Meat": "Carne",
    "Do you want to delete your account?": "L'account utente e tutti i dati associati devono essere cancellati irrevocabilmente?",
    "Fasting Intervals": "Intervalli di digiuno",
    "There is no data in the given time area": "Nessun dato disponibile nel periodo selezionato",
    "Change Password": "Modifica della password",
    "Error": "Errore",
    "Your current password": "Password attuale",
    "Your new password (min. 8 characters)": "Nuova password (min. 8 caratteri)",
    "After the password change you will have to login again": "Dopo aver modificato la password, è necessario effettuare nuovamente il login.",
    "Cancel": "Annullamento",
    "Password changed successfully. Please login again": "La password è stata modificata. Effettuare nuovamente il login",
    "You should receive a mail with a password reset instructions": "È stata inviata un'e-mail di reimpostazione della password. Se non è stata ricevuta alcuna e-mail, controllare la cartella spam.",
    "Invalid E-Mail": "Indirizzo e-mail non valido",
    "Not enough data": "Non sono disponibili dati sufficienti"
  }
}