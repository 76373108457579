function nowInSeconds() {
    return Math.ceil(new Date().getTime() / 1000)
}
function datishStringToDate(dateString) {
    return dateString.constructor === Date
        ? dateString
        : new Date(
              dateString.replace(/(^.+?)\s/, '$1T').replace(/\.\d+Z$/, '') +
                  '.000000Z'
          )
}

function numberAsTwoDigitString(number) {
    return String(Math.abs(number)).padStart(2, '0')
}

function diffInSeconds(startDate, targetHours, now) {
    let targetSecs = targetHours * 60 * 60
    startDate = datishStringToDate(startDate)
    if (!now) {
        now = new Date()
    }
    now = datishStringToDate(now)
    let diff = startDate / 1000 + targetSecs - now / 1000
    return Math.round(diff)
}

function timer(startedAt, now, targetedHours, direction) {
    if (direction !== 'remaining' && direction !== 'processed') {
        direction = 'remaining'
    }

    if (!now) {
        now = new Date()
    }

    let diff =
        direction === 'processed'
            ? targetedHours * 60 * 60 -
              diffInSeconds(startedAt, targetedHours, now)
            : diffInSeconds(startedAt, targetedHours, now)

    let ceilOrFloorMethod = diff > 0 ? 'floor' : 'ceil'

    let seconds = Math.round(diff) % 60
    let minutes = Math[ceilOrFloorMethod](diff / 60) % 60
    let hours = Math[ceilOrFloorMethod](diff / 3600)

    if (diff === 0) {
        return [
            '',
            numberAsTwoDigitString(hours),
            numberAsTwoDigitString(minutes),
            numberAsTwoDigitString(seconds),
        ]
    }

    if (diff > 0) {
        return [
            direction === 'remaining' ? '-' : '+',
            numberAsTwoDigitString(hours),
            numberAsTwoDigitString(minutes),
            numberAsTwoDigitString(seconds),
        ]
    }

    return [
        direction === 'remaining' ? '+' : '-',
        numberAsTwoDigitString(hours),
        numberAsTwoDigitString(minutes),
        numberAsTwoDigitString(seconds),
    ]
}

function calculateAndDisplayTimer(startedAt, intervalInHours, direction) {
    return timer(startedAt, null, intervalInHours, direction)
}

export { timer, diffInSeconds, nowInSeconds, calculateAndDisplayTimer }
