import { useEffect } from 'react'
import { logoutAndClearSession } from '../shared/session_and_user'
import AsyncStorage from '@react-native-async-storage/async-storage'

function Logout() {
    useEffect(() => {
        ;(async () => {
            await logoutAndClearSession()
            await AsyncStorage.clear()
            window.location.href = '/'
        })()
    })

    return <div></div>
}

export default Logout
