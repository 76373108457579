{
  "de": {
    "Yeah, you reached your fasting goal": "Glückwunsch, du hast dein Fastenziel erreicht",
    "Your fasting will be completed": "Das Fasten endet",
    "hour": "Stunde",
    "hours": "Stunden",
    "Hours": "Stunden",
    "Days": "Tage",
    "Fasting started at": "Fasten begann um",
    "Started At": "Begann um",
    "Ended At": "Endete um",
    "Password (at least 8 characters)": "Kennwort (mind. 8 Zeichen)",
    "Confirm Password": "Kennwort wiederholen",
    "Already having an account? Login here": "Bereits ein Benutzerkonto? Hier einloggen",
    "Password must be at least n characters long": "Das Kennwort muss mind. %{count} Zeichen lang sein",
    "Please use a valid email": "Bitte eine korrekte E-Mail-Adresse angeben",
    "Please try another email": "Bitte eine andere E-Mail-Adresse verwenden",
    "Signup done! Please confirm your E-Mail and you are ready to use fastingMonster": "Anmeldung erfolgreich. Abschließend bitte noch den Link in der Bestätigungsemail anklicken und du kannst fasting.monster verwenden.",
    "Add notes after fasting": "Anmerkung nach Fasten aufschreiben",
    "Saved changes": "Änderungen gespeichert",
    "Targeted hours of fasting": "Geplante Fastendauer",
    "InTotalXHoursFasted": "Insgesamt <b>%{hoursOfFasting}</b> gefastet",
    "Signup here if no account": "Noch kein Benutzerkonto? Hier Anmelden",
    "No more fastings found": "Keine weiteren Fastenzeiträume gefunden",
    "Nothing found. Maybe check filter?": "Nichts gefunden. Ggf. Filter checken",
    "Loading": "Wird geladen",
    "Past Fastings": "Fastenintervalle",
    "Minimum Hours": "Minimale Stundenanzahl",
    "All": "Alle",
    "n hours targeted": "%{targeted_hours} Stunden geplant",
    "n hours fasted": "%{hours} Stunden gefasted",
    "hours of fasting": "h Fastendauer",
    "fasted": "gefastet",
    "Add your personal notes": "Anmerkung(en)",
    "Statistics": "Statistik",
    "No data to show": "Keine Daten zum anzeigen",
    "Fastings + Targeted Hours": "Fastenzeit und Fastenintervalle",
    "Download all data": "Alle Daten laden",
    "`Minimum Hours`-filter from fastings is applied by default": "`Minimale Stundenanzahl`-Filter wird berücksichtigt",
    "Fastings": "Intervalle",
    "Color Scheme": "Farbschema",
    "Settings": "Einstellungen",
    "Add notes after finished fasting": "Füge Anmerkung nach Fasten hinzu",
    "Load more": "Mehr laden",
    "E-Mail": "E-Mail",
    "Password": "Kennwort",
    "Your Password": "Benutzerkennwort",
    "Forgot Password?": "Kennwort vergessen?",
    "Your E-Mail": "Deine E-Mail",
    "Password, min n characters": "Kennwort mit mindestens %{count} Zeichen",
    "Delete": "Löschen",
    "Save": "Speichern",
    "Login": "Einloggen",
    "Signup": "Anmeldung",
    "Signup now": "Benutzerkonto erstellen",
    "Logout": "Ausloggen",
    "Open Webapp": "Webapp öffnen",
    "Notes": "Notizen",
    "How much or Any notes?": "Wieviel / Anmerkung?",
    "Water": "Wasser",
    "Tea": "Tee",
    "Coffee": "Kaffee",
    "Salad": "Salat",
    "Vegetables": "Gemüse",
    "Fruits": "Obst",
    "Fish": "Fisch",
    "Meat + Chicken": "Fleisch",
    "Soup": "Suppe",
    "Bread + Wheat": "Brot + Vollkorn",
    "Fast-Food": "Fast-Food",
    "Sweets": "Süßigkeiten",
    "Meal": "Mahlzeit",
    "Alcohol": "Alkohol",
    "Snack": "Snack",
    "Set Weight": "Gewicht eingeben",
    "Your Weight": "Dein Gewicht",
    "Add some personal notes to your fasting": "Füge eine Anmerkung zum Fasten hinzu",
    "lbs": "lbs",
    "kg": "kg",
    "Your fastings": "Vergangenes Fasten",
    "Sure?": "Sicher?",
    "Yes": "Ja",
    "No": "Nein",
    "Offline": "Offline",
    "Please check your internet connection and try again": "Bitte Internetverbindung überprüfen und nochmal versuchen",
    "OK": "OK",
    "n % reached": "%{percentage}% erreicht",
    "There is no more data": "Keine weiteren Daten vorhanden",
    "Fasting and targeted hours": "Fasten- und Intervalldauer",
    "Fasting hours only": "Nur Fastendauer",
    "Current theme": "System",
    "Opposite theme": "Umgekehrt",
    "Back to Login": "Zurück zum Login",
    "Email": "E-Mail",
    "Phone number (optional)": "Optional: Telefonnummer",
    "Appearance": "Farbschema",
    "Authorization failed": "Autorisierung fehlgeschlagen",
    "Nutrition": "Ernährung",
    "Weight": "Gewicht",
    "Bread": "Brot",
    "Fast-food": "Fast-Food",
    "Meat": "Fleisch",
    "Do you want to delete your account?": "Soll der Benutzeraccount und alle dazugehörigen Daten unwiderruflich gelöscht werden?",
    "Fasting Intervals": "Fasten-Intervalle",
    "There is no data in the given time area": "Kein Daten im gewählten Zeitraum vorhanden",
    "Change Password": "Kennwort ändern",
    "Error": "Fehler",
    "Your current password": "Derzeitiges Kennwort",
    "Your new password (min. 8 characters)": "Neues Kennwort (mind. 8 Zeichen)",
    "After the password change you will have to login again": "Nach der Kennwortänderung muss sich neu eingeloggt werden",
    "Cancel": "Abbrechen",
    "Password changed successfully. Please login again": "Kennwort wurde geändert. Bitte neu einloggen",
    "You should receive a mail with a password reset instructions": "Es wurde eine Kennwort-Zurücksetzen-E-Mail versendet. Bitte im Spam-Ordner schauen wenn keine E-Mail empfangen wurde",
    "Invalid E-Mail": "Ungültige E-Mail-Adresse",
    "Not enough data": "Nicht genügend Daten vorhanden"
  }
}