import axios from 'axios'

// axios.defaults.withCredentials = true;
axios.defaults.baseURL =
    window?.location?.host?.startsWith('localhost') ||
    process?.env?.NODE_ENV === 'development'
        ? 'https://api.fasting.monster'//'http://localhost:8000'
        : 'https://api.fasting.monster'

let auth_token = null

// axios.interceptors.request.use((request) => {
//     // console.log('Starting Request', JSON.stringify(request, null, 2))
//     console.log({ url: request.url })
//     return request
// })

export function applyDefaultHeaders(headers = {}) {
    for (let k in headers) {
        axios.defaults.headers.common[k] = headers[k]
    }
}

export function setAuthToken(authToken) {
    auth_token = authToken
    axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
}

export function getAuthToken() {
    return auth_token
}

export async function logout() {
    return await axios.post('/api/v1/logout')
}

export async function user() {
    return await axios.get('/api/v1/user')
}

export async function currentFasting() {
    return await axios.get('/api/v1/fastings/current')
}

export async function fasting(id) {
    return await axios.get(`/api/v1/fastings/${Number(id)}`)
}

export async function deleteFasting(id) {
    return await axios.delete(`/api/v1/fastings/${Number(id)}`)
}

export async function receiveAuthToken(email, password, device_name = null) {
    if (!device_name) {
        device_name = navigator.userAgent.replace(/\s.+$/, '')
    }
    await axios.get('/sanctum/csrf-cookie')
    // this request above did just set cookie in application cookie, otherwise we'll get a CSRF mismatch error
    let { data } = await axios.post('/sanctum/token', {
        email,
        password,
        device_name,
    })
    return data
}

// export async function userSettings() {
//     return await axios.get('/api/v1/user/settings');
// }

export async function fastings({
    per_page = 10,
    page = 1,
    minimum_fasting_hours = null,
} = {}) {
    let params = new URLSearchParams()
    params.set('per_page', per_page)
    params.set('page', page)
    if (minimum_fasting_hours !== null && minimum_fasting_hours >= 0) {
        params.set('minimum_fasting_hours', minimum_fasting_hours)
    }
    return await axios.get(`/api/v1/fastings?${params}`)
}

export async function csrfCookie() {
    return await axios.get('/sanctum/csrf-cookie')
}

export async function updateUserSettings({
    interval_hours,
    timer_counting,
    add_notes_after_finished_fasting,
    minimum_fasting_hours_filter,
    color_scheme,
} = {}) {
    return await axios.patch('/api/v1/user/settings', arguments[0])
}

export async function toggleFasting({
    targeted_hours,
    note = null,
    starts_at = null,
} = {}) {
    return await axios.post('/api/v1/fastings', {
        targeted_hours,
        now: starts_at ? starts_at : new Date(),
        note,
    })
}

export async function updateCurrentFasting({
    targeted_hours,
    starts_at,
    note,
} = {}) {
    return await axios.patch('/api/v1/fastings/current', {
        targeted_hours,
        starts_at,
        note,
    })
}

export async function updateLastFasting({
    targeted_hours,
    starts_at,
    note,
} = {}) {
    return await axios.patch('/api/v1/fastings/last', {
        targeted_hours,
        starts_at,
        note,
    })
}

export async function updateFasting(
    fastingId,
    { targeted_hours, starts_at, ends_at, note } = {}
) {
    if (isNaN(fastingId)) {
        throw Error('id must be numeric')
    }
    return await axios.patch(`/api/v1/fastings/${Number(fastingId)}`, {
        targeted_hours,
        starts_at,
        ends_at,
        note,
    })
}

export async function updateBulkFastings(fastings) {
    return await axios.post(`/api/v1/fastings/bulk`, {
        fastings,
    })
}

export async function updateBulkWeights(weights) {
    return await axios.post(`/api/v1/weights/bulk`, {
        weights,
    })
}

export async function updateBulkIntakes(intakes) {
    return await axios.post(`/api/v1/intakes/bulk`, {
        intakes,
    })
}

export async function signup({ email, password, phone } = {}) {
    return await axios.post('/api/v1/signup', {
        email,
        password,
        phone,
    })
}

export async function emailIsAvailableForSignup(email) {
    let res = await axios.get(`/api/v1/signup/email_is_available/${email}`)
    return res.data.email_is_available && res.data.email_is_valid
}

export async function fastingsExport() {
    let res = await axios.get('/api/v1/export/fastings', {
        responseType: 'blob',
    })
    return res
}

export async function lastWeight() {
    let res = await axios.get('/api/v1/weights/last')
    let lastWeight = null
    if (res?.data?.data?.weight) {
        lastWeight = res.data.data.weight
    }
    return lastWeight
}

export async function weights({ page = 1, per_page = 25 } = {}) {
    let params = new URLSearchParams()
    params.set('per_page', per_page)
    params.set('page', page)
    return (await axios.get(`/api/v1/weights?${params}`))?.data?.data
}

export async function deleteWeight(id) {
    return await axios.delete(`/api/v1/weights/${Number(id)}`)
}

export async function deleteIntake(id) {
    return await axios.delete(`/api/v1/intakes/${Number(id)}`)
}

export async function intakes({ page = 1, per_page = 25 } = {}) {
    let params = new URLSearchParams()
    params.set('per_page', per_page)
    params.set('page', page)
    return (await axios.get(`/api/v1/intakes?${params}`))?.data?.data
}

export async function setWeight(weight, unit) {
    return await axios.post('/api/v1/weights', {
        weight,
        unit,
    })
}

export async function lastIntakeNote(which) {
    let last = await axios.get(
        `/api/v1/intakes/last/${encodeURIComponent(which)}`
    )
    return last?.data?.data?.note || null
}

export async function lastIntakeNotes() {
    let last = await axios.get(`/api/v1/intakes/last`)
    return last?.data || {}
}

export async function addIntake(which, note) {
    return await axios.post('/api/v1/intakes', {
        which,
        note,
    })
}

export async function sendPasswordReset(email) {
    return await axios.post('/api/v1/forgot-password', {
        email,
    })
}

export async function changePassword({ current_password, new_password } = {}) {
    return await axios.patch('/api/v1/password', {
        current_password,
        new_password,
    })
}

export async function fastingCharts({ page } = {}) {
    let params = new URLSearchParams()
    if (page) {
        params.set('page', page)
    }
    return await axios.get(`/api/v1/charts/fastings?${params}`)
}

export async function weightsCharts({ page } = {}) {
    let params = new URLSearchParams()
    if (page) {
        params.set('page', page)
    }
    return await axios.get(`/api/v1/charts/weights?${params}`)
}

export async function health({ timeoutInSeconds = null } = {}) {
    let options = {}
    if (timeoutInSeconds) {
        options = { timeout: timeoutInSeconds * 1000 }
    }
    return await axios.get('/api/v1/healthz', options)
}

export async function version() {
    return await axios.get('/api/v1/version')
}

export async function summary() {
    return (await axios.get('/api/v1/summary')).data
    // return {
    //     user: data.user,
    //     userSettings: data.userSettings,
    //     currentFasting: data.currentFasting,
    //     lastFastings: data.lastFastings,
    //     lastIntakes: data.lastIntakes,
    //     currentWeight: data.currentWeight,
    //     version: data.version,
    // }
}

export async function deleteAccount(password) {
    return await axios.delete('/api/v1/account', { data: { password } })
}
