import './settings.css'

import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as api from '../shared/api'
import { t } from '../shared/lib/helper'
import * as documentHelper from '../shared/lib/document_helper'
import AsyncStorage from '@react-native-async-storage/async-storage'

function Settings(props) {
    const [userSettings, setUserSettings] = useState(null)
    const [email, setEmail] = useState(null)
    const [version, setVersion] = useState(null)

    async function loadUserSettings() {
        let user = (await api.user()).data.data
        setUserSettings(JSON.parse(user.settings))
        setEmail(user.email)
    }

    async function updateUserSettings() {
        await api.updateUserSettings(arguments[0])
        await loadUserSettings()
    }

    async function selectColorSchemeHandler(ev) {
        let color_scheme = ev.target.value
        documentHelper.applyColorSchemeOnDocument(color_scheme)
        await updateUserSettings({ color_scheme })
    }

    async function addNotesAfterFinishedFastingHandler(ev) {
        updateUserSettings({
            add_notes_after_finished_fasting: ev.target.checked,
        })
    }

    async function handleDeleteAccount(ev) {
        ev.preventDefault()
        if (confirm(t('Do you want to delete your account?'))) {
            let password = prompt(t('Your password'))
            try {
                await api.deleteAccount(password)
                await AsyncStorage.clear()
                alert(
                    'Sorry to see you go. If you might change your mind in the future, you can signup a new account any time!'
                )
                window.location.href = '/'
            } catch (e) {
                let message = e.response?.data?.error || e.message || e
                alert(`Could not delete account: ${message}`)
            }
        }
    }

    useEffect(() => {
        if (!userSettings) {
            loadUserSettings()
            api.version().then((v) => setVersion(v.data.version))
        }
    })

    return (
        <div className="settings full-height-and-centered">
            <header>
                <h1>{t('Settings')}</h1>
            </header>
            {userSettings && (
                <form className="form">
                    <label htmlFor="color-scheme">
                        <div>{t('Color Scheme')}</div>
                        <select
                            name="color-scheme"
                            className="select"
                            value={userSettings.color_scheme}
                            onChange={selectColorSchemeHandler}
                        >
                            <option value="auto">Auto</option>
                            <option value="dark">Dark</option>
                            <option value="light">Light</option>
                        </select>
                    </label>

                    <label
                        htmlFor="add-notes-after-finished-fasting"
                        className="with-checkbox"
                    >
                        <div>{t('Add notes after finished fasting')}</div>
                        <input
                            name="add-notes-after-finished-fasting"
                            id="add-notes-after-finished-fasting"
                            type="checkbox"
                            onChange={addNotesAfterFinishedFastingHandler}
                            checked={
                                userSettings.add_notes_after_finished_fasting
                            }
                        ></input>
                    </label>
                    <a
                        href="#"
                        className="button delete"
                        onClick={handleDeleteAccount}
                    >
                        {t('Delete Account')}
                    </a>
                    <Link to="/logout" className="button">
                        {t('Logout')}
                    </Link>
                </form>
            )}
            <div className="version">
                {email} &nbsp;&nbsp; v{version}
            </div>
        </div>
    )
}

export default Settings
