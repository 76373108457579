import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

if (window.location.host.startsWith('app.fasting.monster')) {
    Sentry.init({
        dsn: 'https://b82f3b3012a34d5f813e4f4b6140b79b@o1128238.ingest.sentry.io/6237824',
        integrations: [new BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })
}

import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './app'

import './shared/i18n/i18n'

const root = createRoot(document.getElementById('app'))
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// https://codepen.io/alsheuski/pen/eJNwNX
