import './signup.css'
import monsterHead from '../../images/monster-alone.svg'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as api from '../shared/api'
import { t } from '../shared/lib/helper'
import { useState } from 'react'

function Login() {
    const [formErrorMessage, setFormErrorMessage] = useState('')
    const navigate = useNavigate()

    async function handleSubmit(event) {
        event.preventDefault()
        const email = event.target.querySelector('input[name="email"]').value
        const password = event.target.querySelector(
            'input[name="password"]'
        ).value
        const passwordConfirm = event.target.querySelector(
            'input[name="password-confirm"]'
        ).value
        if (password !== passwordConfirm) {
            setFormErrorMessage('Passwords do not match')
            return
        }

        if (!formErrorMessage) {
            await api.signup({
                email,
                password,
            })
            alert(
                t(
                    'Signup done! Please confirm your E-Mail and you are ready to use fastingMonster'
                )
            )
            navigate('/login?from=initial_signup')
        }
    }

    async function validateEmail(event) {
        const validMail = function (mail) {
            return /^[^@]+@[^@]+\..+$/.test(mail)
        }
        const email = event.target.value

        clearErrorMessage()

        if (!validMail(email)) {
            return
        }
        let emailIsAvailable = await api.emailIsAvailableForSignup(email)
        if (!emailIsAvailable) {
            setFormErrorMessage(t('Please try another email'))
            return
        }
    }

    function clearErrorMessage() {
        setFormErrorMessage('')
    }

    return (
        <div className="signup full-height-and-centered no-space-between">
            <header>
                <h1>{t('Signup')}</h1>
                <Link to="/login">
                    <img src={monsterHead} alt="Fasting Monster" />
                </Link>
            </header>
            <form className="form" onSubmit={handleSubmit}>
                {formErrorMessage && (
                    <div className="form-message error">{formErrorMessage}</div>
                )}
                <label htmlFor="email">
                    <div>{t('E-Mail')}</div>
                    <input
                        type="email"
                        placeholder={t('Your E-Mail')}
                        required
                        name="email"
                        onChange={validateEmail}
                    ></input>
                </label>
                <label htmlFor="password">
                    <div>{t('Password, min n characters', { count: 8 })}</div>
                    <input
                        type="password"
                        minLength={8}
                        required
                        name="password"
                        onChange={clearErrorMessage}
                    ></input>
                </label>
                <label htmlFor="password-confirm">
                    <div>{t('Confirm Password')}</div>
                    <input
                        type="password"
                        minLength={8}
                        required
                        name="password-confirm"
                        onChange={clearErrorMessage}
                    ></input>
                </label>
                <button className="button" role="submit">
                    {t('Signup now')}
                </button>
            </form>
            <Link to="/login">
                {t('Already having an account? Login here')}
            </Link>
        </div>
    )
}

export default Login
